<template>
  <div class=""></div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    this.getToken();
  },
  mounted() {},
  methods: {
    getToken() {
      let query = this.$route.query;
      if (!query.token && query.game_id) {
        let token  = localStorage.getItem("token")
        if (token) {
          this.$router.push("/consume");
        }else{
          this.$router.push({ path: "/login", query: { g: query.game_id } });
        }
        return;
      }
      let params = {
        ...query,
      };
      this.$http.get("/login/autoLogin", { params }).then((res) => {
        if (res.data.code === 200) {
          let token = res.data.data.token;
          localStorage.setItem("token", JSON.stringify(token));
          this.selectServe = true;
          this.$router.push("/consume");
          setTimeout(() => {
            this.showLoading = false;
          }, 2000);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
